import React, { forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';
import { useInView } from 'react-intersection-observer';
import Acknowledged from '../images/acknowledged.webp';
import Tend from '../images/tend.webp';
import Github from '../images/github.png';
import SWEShape from './shapes/SWEShape';


// const SWE_DESCRIPTION = 'here is full list of experiences. here are some of my public projects.';

const ImagePortfolioBox = styled(motion.a).attrs({
  className: 'w-24 h-24 xl:w-44 xl:h-44 m-2 border-solid border border-gray-300',
})`
  background: url("${(props) => props.imageUrl}") no-repeat center center;
  background-size: contain;
  position: relative;
  &:before {
    content: "";
    border: dashed 1px gray;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0.5rem;
    left: 0.5rem;
    z-index: -5;
  }
`;

const ImagePortfolioHoverTitle = styled.div.attrs({
  className: 'w-full h-full flex flex-col justify-center align-center text-sm bg-gray-700 bg-opacity-90 text-center text-white opacity-0 hover:opacity-100 transition-opacity z-10',
})``;

const getVariants = (delay) => ({
  visible: { opacity: 1, x: 0, transition: { duration: 0.5, ...(delay ? { delay } : {}) } },
  hidden: { opacity: 0, x: 100 },
});

const SWEPortfolio = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const variants = [getVariants(), getVariants(0.3), getVariants(0.6)];

  return (
    <div ref={ref} className="flex flex-row justify-center xl:justify-start  w-full z-10 mb-12 xl:mb-0">
      <ImagePortfolioBox
        whileHover={{
          scale: 1.05,
          y: -10,
          transition: { duration: 0.2 },
        }}
        variants={variants[0]}
        initial="hidden"
        animate={controls}
        imageUrl={Acknowledged}
        href="https://github.com/lowewenzel/acknowledged-bot"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImagePortfolioHoverTitle>
          AcknowledgedBot
        </ImagePortfolioHoverTitle>
      </ImagePortfolioBox>
      <ImagePortfolioBox
        whileHover={{
          scale: 1.05,
          y: -10,
          transition: { duration: 0.2 },
        }}
        variants={variants[1]}
        initial="hidden"
        animate={controls}
        imageUrl={Tend}
        href="https://tend.wnzl.dev"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImagePortfolioHoverTitle>
          Tend
        </ImagePortfolioHoverTitle>
      </ImagePortfolioBox>
      <ImagePortfolioBox
        whileHover={{
          scale: 1.05,
          y: -10,
          transition: { duration: 0.2 },
        }}
        variants={variants[2]}
        initial="hidden"
        animate={controls}
        imageUrl={Github}
        href="https://github.com/lowewenzel"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImagePortfolioHoverTitle>
          Github
        </ImagePortfolioHoverTitle>
      </ImagePortfolioBox>
    </div>
  );
};

const StripedSWEPolygon = styled(SWEShape)`
  position: absolute;
  left: 40px;
  top: 40px;
`;

const SWEPolygons = () => (
  <Parallax className="z-0 absolute w-2/6 h-full right-0  mt-80 xl:mt-0 hidden xl:block" x={[-10, 10]}>
    <SWEShape />
    <StripedSWEPolygon noFill />
    {/* <SWEPolygon src={SWEShape} />
    <StripedSWEPolygon src={SWEShape} /> */}
  </Parallax>

);

const Software = (props, ref) => (
  <Parallax ref={ref}>
    <div className="w-full h-screen/2 xl:h-screen flex flex-col justify-center items-center" id="software">
      <div className="flex flex-col items-center justify-end xl:grid xl:grid-cols-2 w-full  h-full flex-1 -mt-64 xl:mt-0">
        <div className="w-full flex items-center justify-center z-10">
          <div className="container w-9/12 text-left">

            <motion.h1
              className="text-5xl lg:text-6xl xl:text-7.5xl font-display font-bold"
              whileHover={{ y: -10 }}
              transition={{
                duration: 0.5,
              }}
            >
              software engineer
            </motion.h1>
            <div className="container my-7 max-w-xl">
              <p className="text-2xl uppercase">
                experience in react, angular, nextjs, nodejs, java, and ruby on rails tech stacks.
                &nbsp;
              </p>

            </div>
          </div>
        </div>
        <div className="container flex items-center justify-center">

          <SWEPolygons />
          <SWEPortfolio />
        </div>
      </div>

    </div>
  </Parallax>
);

export default forwardRef(Software);
